import { trpcClient } from '@icecreamswap/backend'
import { TokenList } from '@pancakeswap/token-lists'
import { QUOTING_API } from 'config/constants/endpoints'
import { generateToken } from './jwtToken'

// Cache structure for storing data with expiration
type CacheEntry<T> = {
  data: T
  timestamp: number
  inProgress?: Promise<T>
}

// Map to store cached data
const cache: Map<string, CacheEntry<any>> = new Map()

// Utility function for caching with TTL and call deduplication
function getCachedData<T>(key: string, fetchFn: () => Promise<T>, ttl: number): Promise<T> {
  const now = Date.now()
  const cached = cache.get(key)

  if (cached) {
    // Return cached data if valid
    if (now - cached.timestamp < ttl) {
      return Promise.resolve(cached.data)
    }

    // If a fetch is already in progress, return the same Promise
    if (cached.inProgress) {
      return cached.inProgress
    }
  }

  // If no valid cache or in-progress fetch, start a new fetch
  const inProgress = fetchFn().then((data) => {
    cache.set(key, { data, timestamp: Date.now() }) // Update cache
    return data
  }).catch((error) => {
    // Remove in-progress state if fetch fails
    cache.delete(key)
    throw error
  })

  // Set the in-progress Promise in the cache
  cache.set(key, { data: cached?.data, timestamp: cached?.timestamp || 0, inProgress })

  return inProgress
}

// Default token list retrieval
export const getDefaultTokenList = async (): Promise<TokenList> => {
  // @ts-ignore
  return trpcClient.token.defaultList.query()
}

// Puush Graduates List retrieval with caching and deduplication
export const getPuushGraduatesList = async (chainId: number): Promise<TokenList | null> => {
  if (!chainId) {
    return null
  }

  const key = `puushGraduatesList-${chainId}`
  const ttl = 15000 // Cache time-to-live in milliseconds (15 seconds)

  // Function to fetch data from the API
  const fetchPuushGraduatesList = async (): Promise<TokenList | null> => {
    try {
      const token = await generateToken()
      const response = await fetch(`${QUOTING_API}/${chainId}/v1/graduates`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })

      if (!response.ok) {
        console.log('Error fetching Puush Graduates token list')
        return null
      }

      const tokenList: TokenList = await response.json()
      return tokenList
    } catch (error) {
      console.log('Error fetching Puush Graduates token list:', error)
      return null
    }
  }

  // Use cached data with deduplication
  return getCachedData(key, fetchPuushGraduatesList, ttl)
}