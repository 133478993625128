import { QUOTING_API } from 'config/constants/endpoints'

interface TokenPriceResponse {
  price: string | null
}

export const fetchTokenPrice = async (chainId: number, tokenAddress: string): Promise<string | null> => {
  if (!tokenAddress || !chainId) return null

  try {
    const response = await fetch(
      `${QUOTING_API}/${chainId}/v1/getPrice?address=${tokenAddress}`
    )

    if (!response.ok) {
      throw new Error('Failed to fetch token price')
    }

    const data: TokenPriceResponse = await response.json()
    return data.price
  } catch (err) {
    console.error('Error fetching token price:', err)
    return null
  }
}