import React from "react";
import styled, { keyframes } from "styled-components";
import Image from "next/image";
import Obsidian from "../../img/ai.png";
import { SpinnerProps } from "./types";

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(90deg); }
  50% { transform: rotate(180deg); }
  75% { transform: rotate(270deg); }
  100% { transform: rotate(360deg); }
`;

const float = keyframes`
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(10px);
	}
	100% {
		transform: translatey(0px);
	}
`;

const Container = styled.div`
  position: relative;
  height: 180px;
`;

const IceImage = () => {
  return <Image src={Obsidian} width="75" height="75" alt="spinner" />;
};

const RotatingPancakeIcon = styled.div`
  animation: ${rotate} 5s cubic-bezier(0.25, 1, 0.5, 1) infinite;
  transform-origin: center;
`;

const Spinner: React.FC<React.PropsWithChildren<SpinnerProps>> = ({ size = 128 }) => {
  return (
    <Container>
      <RotatingPancakeIcon>
        <IceImage />
      </RotatingPancakeIcon>
    </Container>
  );
};

export default Spinner;
