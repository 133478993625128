import { Address, zeroAddress } from 'viem'
import { getLPTokenPrice } from './getLpPrice'
import { cronosTokens, USD } from '@pancakeswap/tokens'
import { fetchTokenPrice } from './ObsidianPricing'

const baseUrl = 'https://api.dexscreener.com/latest/dex/tokens/'
const lpTokenAddresses = [
  cronosTokens.retribLp.address,
  cronosTokens.croraLp.address,
  cronosTokens.yoshiLp.address,
  cronosTokens.pmrLp.address,
  cronosTokens.warioLp.address,
  cronosTokens.fafLp.address,
]

interface TokenPair {
  baseToken: {
    address: string
    name: string
    symbol: string
  }
  quoteToken: {
    address: string
    name: string
    symbol: string
  }
  priceUsd: string
  liquidity: {
    usd: number
  }
}

// Helper function to get stable tokens for a chain
const getChainStableTokens = (chainId: number): string[] => {
  const stableTokens: string[] = []

  // Add USD token if it exists for this chain
  if (USD[chainId]) {
    stableTokens.push(USD[chainId].address)
  }

  // Add chain-specific stable tokens
  switch (chainId) {
    case 25: // Cronos chain
      if (cronosTokens.usdc) {
        stableTokens.push(cronosTokens.usdc.address)
      }
      break
    // Add more chain-specific cases here as needed
  }

  // Remove duplicates and filter out undefined/null values
  return [...new Set(stableTokens)].filter(Boolean)
}

// Helper function to check if an address is an LP token
const isLPToken = (address: string): boolean => {
  return lpTokenAddresses.some(
    (lpAddress) => lpAddress.toLowerCase() === address.toLowerCase()
  )
}

export const dexScreenerUSD = async (chainId: number, tokenAddresses: string[]) => {
  if (!tokenAddresses.length) return new Map<string, string>()

  // Filter out the Ethereum zero address
  const filteredAddresses = tokenAddresses.filter((address) => address !== zeroAddress)

  if (!filteredAddresses.length) return new Map<string, string>()

  const tokenUrl = `${baseUrl}${filteredAddresses.join(',')}`
  const chainStableTokens = getChainStableTokens(chainId)
  const commonTokenUSDValue = new Map<string, string>()

  // If chain is 388, skip DexScreener and use Obsidian API directly
  if (chainId === 388) {
    for (const tokenAddress of filteredAddresses) {
      const price = await fetchTokenPrice(chainId, tokenAddress)
      if (price) {
        commonTokenUSDValue.set(tokenAddress, price)
      }
    }
    return commonTokenUSDValue
  }

  try {
    // Fetch token prices from Dexscreener API
    const result = await fetch(tokenUrl).then((res) => res.json())
    const tokensData: TokenPair[] = result?.pairs || []

    // Process the token data and find the pair with highest liquidity for each token
    for (const tokenAddress of filteredAddresses) {
      const lowercaseTokenAddress = tokenAddress.toLowerCase()

      // Check if the token is a stable token for the current chain
      if (chainStableTokens.some((stableToken) => stableToken.toLowerCase() === lowercaseTokenAddress)) {
        commonTokenUSDValue.set(tokenAddress, '1')
        continue
      }

      // Check if the token is the LP token
      if (isLPToken(lowercaseTokenAddress)) {
        const lpTokenPrice = await getLPTokenPrice(chainId, tokenAddress as Address)
        commonTokenUSDValue.set(tokenAddress, lpTokenPrice.toString())
      } else {
        // Find all pairs where the token is specifically the base token
        const tokenPairs = tokensData.filter(
          (pair) => pair.baseToken.address.toLowerCase() === lowercaseTokenAddress
        )

        // Filter out pairs without priceUsd and sort by liquidity
        const validTokenPairs = tokenPairs
          .filter((pair) => pair.priceUsd !== undefined && pair.liquidity?.usd > 0)
          .sort((a, b) => b.liquidity.usd - a.liquidity.usd)

        if (validTokenPairs.length > 0) {
          const highestLiquidityPair = validTokenPairs[0]
          const tokenPrice = parseFloat(highestLiquidityPair.priceUsd)

          if (!isNaN(tokenPrice)) {
            commonTokenUSDValue.set(tokenAddress, tokenPrice.toString())
          } else {
            // Fallback to Obsidian API if DexScreener price is invalid
            const obsidianPrice = await fetchTokenPrice(chainId, tokenAddress)
            if (obsidianPrice) {
              commonTokenUSDValue.set(tokenAddress, obsidianPrice)
            } else {
              console.warn(`Invalid price value for token: ${tokenAddress}`)
            }
          }
        } else {
          // Fallback to Obsidian API if no valid pairs found
          const obsidianPrice = await fetchTokenPrice(chainId, tokenAddress)
          if (obsidianPrice) {
            commonTokenUSDValue.set(tokenAddress, obsidianPrice)
          } else {
            console.warn(`No valid pairs found where token ${tokenAddress} is the base token`)
          }
        }
      }
    }

    return commonTokenUSDValue
  } catch (error) {
    console.warn('Error while fetching token price:', error)
    
    // If DexScreener fails completely, try Obsidian API for all tokens
    for (const tokenAddress of filteredAddresses) {
      const price = await fetchTokenPrice(chainId, tokenAddress)
      if (price) {
        commonTokenUSDValue.set(tokenAddress, price)
      }
    }
    
    return commonTokenUSDValue
  }
}