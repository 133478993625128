import React from 'react';
import styled from 'styled-components';
import { NextLinkFromReactRouter, Skeleton, Text } from '@pancakeswap/uikit';
import { useAccount } from 'wagmi';
import { useTradeStatus } from 'views/Rewards/hooks/useTradeStatus';

const MIN_TRADE_VALUE:number = Number(process.env.NEXT_PUBLIC_MIN_TRADE_VALUE) || 10;

export const DiamondIcon = styled.img`
  min-width: 32px;
  width: 32px;
  height: 32px;
  margin-right: 0px;
`;

const DiamondContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 4px 12px;
`;

const DiamondCounter: React.FC = () => {
  const { isConnected } = useAccount();
  const { isLoading, diamonds } = useTradeStatus(MIN_TRADE_VALUE);

  if (!isConnected) return null;

  return (
    <NextLinkFromReactRouter to="/rewards">
        <DiamondContainer>
            <DiamondIcon src="/images/diamond.svg" alt="Diamonds" />
            <Text color="white" fontSize="16px" fontWeight="bold">
                {isLoading ? <Skeleton width={10} height={10} /> : diamonds}
            </Text>
        </DiamondContainer>
    </NextLinkFromReactRouter>
  );
};

export default DiamondCounter;